import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Select, Checkbox, Button, Spin, Typography, Row, Col, message, Switch, Radio, Divider } from 'antd';
import { CopyOutlined, CloseCircleOutlined, SelectOutlined, CloseOutlined,SendOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import EmailSender from './EmailSender';


const { Text } = Typography;
const { Option } = Select;
const { Title } = Typography;



const AddEvaluatee = () => {
    const [form] = Form.useForm();
    const [evaluatorForm] = Form.useForm();
    const [companies, setCompanies] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [logos, setLogos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState('');
    const [language, setLanguage] = useState('English');
    const [isNewCompany, setIsNewCompany] = useState(false);
    const [toggleLanguage, setToggleLanguage] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [evaluateeID, setEvaluateeID] = useState('');
    const [hash, setHash] = useState('');
    const [evaluators, setEvaluators] = useState([{ name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }]);
    //const [companyName, setCompanyName] = useState('');
    const [numberOfQuestionsSelected, setNumberOfQuestionsSelected] = useState(0);
    const [fullName, setFullName] = useState('');
    const [isEvaluatorSubmitted, setIsEvaluatorSubmitted] = useState(false);
    const [selectedLogo, setSelectedLogo] = useState('');
    const [evaluatorLoading, setEvaluatorLoading] = useState(false);
    const [selectedName, setSelectedName] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [companyId, setCompanyId] = useState('');

    useEffect(() => {
        fetchCompanies();
        fetchQuestions();
        fetchLogos();
    }, [language]);


    const fetchCompanies = async () => {
        try {
            const response = await axios.get('https://backend.idacds.systems/companiesi', {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                }
              });
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const handleNameChange = (value) => {
        setSelectedName(value);
        setSelectedYear('');  // Reset year when name changes
        setCompanyId('');     // Reset ID when name changes
    };
    
    const handleYearChange = (value) => {
        setSelectedYear(value);
    
        // Find the company ID based on selected name and year
        const selectedCompany = companies.find(company => 
            company.CompanyName === selectedName && company.CompanyYear === parseInt(value)
        );
        setCompanyId(selectedCompany ? selectedCompany.CompanyID : 'Not found');
    };
    

    const companyNames = [...new Set(companies.map(company => company.CompanyName))];
    const companyYears = selectedName ? 
        [...new Set(companies.filter(company => company.CompanyName === selectedName).map(company => company.CompanyYear))] : [];


    const handleCheckboxChange = (e) => {
        setIsNewCompany(e.target.checked);
    };

    const fetchQuestions = async () => {
        try {
            const apiUrl = toggleLanguage ? 'https://backend.idacds.systems/questionlistsin' : 'https://backend.idacds.systems/questionlist';
            const response = await axios.get(apiUrl, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setQuestions(response.data);
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };

    const fetchLogos = async () => {
        try {
            const response = await axios.get('https://backend.idacds.systems/logos', {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                }
              });
            setLogos(response.data);
        } catch (error) {
            console.error('Error fetching logos:', error);
        }
    };

    const handleSubmit = async (values,) => {
        try {
            let companyIdToUse = companyId; // Use the current state of companyId initially
            let companyNameToUse = selectedName;
    
            if (isNewCompany) {
                const response = await axios.post('https://backend.idacds.systems/add-company', {
                    companyName: values.newCompanyName,
                    companyYear: values.newCompanyYear
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                        // Add any other headers here if needed
                    }
                });
                companyIdToUse = response.data.CompanyID; // Update companyIdToUse with the newly added company ID
                companyNameToUse = values.newCompanyName; // Set the new company name
                await fetchCompanies(); // Refresh the list of companies
            } else {
                companyNameToUse = selectedName; // Use the selected company name from the dropdown
            }
    
            // Add evaluatee using the obtained companyIdToUse
            const response = await axios.post('https://backend.idacds.systems/add-evaluatee', {
                firstName: values.firstName,
                lastName: values.lastName,
                designation: values.designation,
                companyId: companyIdToUse, // Use the updated companyIdToUse
                questions: selectedQuestions,
                logoURL: selectedLogo
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
    
            const hash = response.data.hash;
            setEvaluateeID(response.data.evaluateeID);
            setNumberOfQuestionsSelected(selectedQuestions.length);
            setHash(hash);
            setUrl(`https://quiz.idacds.systems/form/${hash}`);
    
            // Reset form fields and state after successful submission
            form.resetFields(['firstName', 'lastName', 'designation', 'companyId', 'newCompanyName', 'newCompanyYear', 'questions', 'logoURL']);
            evaluatorForm.resetFields();
            setSelectedQuestions([]);
            setSelectedCategories([]);
            setSelectedName(companyNameToUse); // Set the selected company name
            setFullName(`${values.firstName} ${values.lastName}`);
            setEvaluators([{ name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }]);
            setIsEvaluatorSubmitted(false);
            message.success('Evaluatee added successfully!');

            if (isNewCompany) {
                setSelectedName(values.newCompanyName); // Set the new company name for passing to EmailSender
            } else {
                setSelectedName(companyNameToUse); // Set the existing company name for passing to EmailSender
            }
            
        } catch (error) {
            console.error('Error adding evaluatee:', error);
            message.error('Error adding evaluatee');
        } finally {
            setLoading(false);
        }
    };    

    const handleSubmitEvaluator = async () => {
        setEvaluatorLoading(true);
        try {
            const response = await axios.post('https://backend.idacds.systems/evaluatee-evaluators', {
                evaluateeID: hash,
                evaluators: evaluators,
                NumberOfQuestions: numberOfQuestionsSelected,
                URL: url
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setIsEvaluatorSubmitted(true);
            message.success('Evaluator details submitted successfully!');
        } catch (error) {
            console.error('Error submitting evaluator details:', error);
            message.error('Error submitting evaluator details');
        } finally {
            setEvaluatorLoading(false);
        }
    };

    const handleCompanyChange = (value) => {
        setIsNewCompany(value === 'new');
    };

    const handleCopyUrl = () => {
        if (url) {
            navigator.clipboard.writeText(url);
            message.success('URL copied to clipboard');
        } else {
            message.error('URL is not available');
        }
    };

    const handleClear = () => {
        form.resetFields(['firstName', 'lastName', 'designation', 'companyId', 'newCompanyName', 'questions', 'logoURL']);
        evaluatorForm.resetFields();
        setSelectedQuestions([]);
        setSelectedCategories([]);
        setUrl('');
    };

    const handleToggleLanguage = (checked) => {
        setToggleLanguage(checked);
        setLanguage(checked ? 'Sinhala' : 'English');
    };

    const handleSelectAll = () => {
        const allQuestionIds = questions.map(question => question.id);
        setSelectedQuestions(allQuestionIds);
        const allCategories = categories;
        setSelectedCategories(allCategories);
        form.setFieldsValue({ questions: allQuestionIds });
    };

    const handleDeselectAll = () => {
        setSelectedQuestions([]);
        setSelectedCategories([]);
        form.setFieldsValue({ questions: [] });
    };

    const handleCategoryToggle = (category) => {
        const categoryQuestions = questions.filter(q => q.category === category).map(q => q.id);
        let updatedSelectedQuestions;
        let updatedSelectedCategories;
        if (selectedCategories.includes(category)) {
            updatedSelectedQuestions = selectedQuestions.filter(qId => !categoryQuestions.includes(qId));
            updatedSelectedCategories = selectedCategories.filter(c => c !== category);
        } else {
            updatedSelectedQuestions = [...new Set([...selectedQuestions, ...categoryQuestions])];
            updatedSelectedCategories = [...new Set([...selectedCategories, category])];
        }
        setSelectedQuestions(updatedSelectedQuestions);
        setSelectedCategories(updatedSelectedCategories);
        form.setFieldsValue({ questions: updatedSelectedQuestions });
    };

    const isCategorySelected = (category) => selectedCategories.includes(category);

    const handleEvaluatorChange = (index, key, value) => {
        const newEvaluators = [...evaluators];
        newEvaluators[index][key] = value;
        setEvaluators(newEvaluators);
    };

    const categories = [...new Set(questions.map(question => question.category))];

    return (
        <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
      <Title>Nominee Submission</Title>
      <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
      Add Nominee Information And Questions.
            </Title>
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '7px',
            }}>
                            <Row gutter={24}>
                                <Col span={4}>
                                    <Form.Item
                                        label={<Title level={5} style={{padding:0 , marginTop: 27, marginBottom: 0}}>First Name</Title>}
                                        name="firstName"
                                        rules={[{ required: true, message: 'Please input first name' }]}
                                        
                                    >
                                        <Input 
                                            style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            }}
                                        
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label={<Title level={5} style={{padding:0 , marginTop: 27, marginBottom: 0}}>Last Name</Title>}
                                        name="lastName"
                                        rules={[{ required: true, message: 'Please input last name' }]}
                                    >
                                        <Input style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label={<Title level={5} style={{padding:0 , marginTop: 27, marginBottom: 0}}>Designation</Title>}
                                        name="designation"
                                        rules={[{ required: true, message: 'Please input designation' }]}
                                    >
                                        <Input style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
        <Form.Item
            label={<Title level={5} style={{padding:0 , marginTop: 27, marginBottom: 0}}>Is this a new company?</Title>}
            valuePropName="checked"
        >
            <Checkbox onChange={handleCheckboxChange}>Add New Company</Checkbox>
        </Form.Item>
    </Col>
    {isNewCompany ? (
        <>
            <Col span={4}>
                <Form.Item
                    label={<Title level={5} style={{padding:0 , marginTop: 27, marginBottom: 0}}>Company Name</Title>}
                    name="newCompanyName"
                    rules={[{ required: true, message: 'Please input new company name' }]}
                >
                    <Input style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            }}
                                        />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item
                    label={<Title level={5} style={{padding:0 , marginTop: 27, marginBottom: 0}}>Company Year</Title>}
                    name="newCompanyYear"
                    rules={[{ required: true, message: 'Please input new company year' }]}
                >
                    <Input style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            }}
                                        />
                </Form.Item>
            </Col>
        </>
    ) : (
        <>
            <Col span={4}>
                <Form.Item
                    label={<Title level={5} style={{padding:0 , marginTop: 27, marginBottom: 0}}>Company Name</Title>}
                    name="companyId"
                    rules={[{ required: true, message: 'Please select a company name' }]}
                >
                    <Select 
                        value={selectedName} 
                        onChange={handleNameChange}
                        style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
                                        
                    >
                        {companyNames.map(name => (
                            <Option key={name} value={name}>{name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item
                    label={<Title level={5} style={{padding:0 , marginTop: 27, marginBottom: 0}}>Company Year</Title>}
                    name="companyYear"
                    rules={[{ required: true, message: 'Please select a company year' }]}
                >
                    <Select 
                        value={selectedYear} 
                        onChange={handleYearChange} 
                        disabled={!selectedName}
                        style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
                    >
                        {companyYears.map(year => (
                            <Option key={year} value={year}>{year}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        </>
    )}
</Row>       
</div>  
            </Form>
            <Row justify="center" gutter={[16, 16]}>
                <Col span={12}>
                    <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px',
                minHeight: '1058px'
            }} >
                        <Form layout="vertical" form={form} onFinish={handleSubmit}>       
                            <Form.Item
                                name="questions"
                                rules={[{ required: true, message: 'Please select questions' }]}
                            >
                                <Row gutter={[24, 24]} style={{ paddingBottom: '20px' }}>
                                    <Col span={7} >
                                        <Form.Item 
                                            name="language"
                                        >
                                        <div style={
                                            {
                                                marginTop:'20px'
                                            }
                                        }>
                                        <Text style={{ fontSize: '16px', marginRight: '10px' }}>English</Text>
                                        <Switch
                                            checked={language === 'Sinhala'}
                                            onChange={handleToggleLanguage}
                                            style={{ margin: '0px', backgroundColor:'#3B7DDE' }} // Add margin for spacing
                                        />
                                        <Text style={{ fontSize: '16px', marginLeft: '10px' }}>Sinhala</Text>  
                                        </div>                                  
                                        </Form.Item>
                                    </Col>

                                    <Col span={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Button onClick={handleSelectAll} icon={<SelectOutlined />} style={{ marginRight: '5px', marginLeft: '5px', marginTop:"-5px" }} />
                                        <Button onClick={handleDeselectAll} icon={<CloseOutlined />} style={{ marginRight: '5px', marginLeft: '5px',marginTop:"-5px"}} />
                                    </Col>
                                    <Col span={7} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        {categories.map((category) => (
                                            <Button
                                                key={category}
                                                type={isCategorySelected(category) ? "primary" : "default"}
                                                onClick={() => handleCategoryToggle(category)}
                                                style={{ marginRight: '5px', marginBottom: '5px', }}
                                            >
                                                {category}
                                            </Button>
                                        ))}
                                    </Col>
                                    <Col span={5}>
                                    <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading} block
                            style={{marginTop:'17px', maxWidth: '60%', float:'right',backgroundColor: '#3B7DDE'}}>
                                SUBMIT  
                            </Button>
                        </Form.Item>
                                    </Col>
                              
                                </Row>
                                <div style={{
                                    padding: '0px',
                                    marginTop: '-30px',
                                    marginBottom: '20px',
                                    backgroundColor: '#F6F7FB',
                                    borderRadius: '10px',
                                    }}>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Title level={5} style={{ marginLeft: '10px', paddingLeft:'14px'  }}>Question Content</Title>
                                        </div>
                                        </Col>
                                        <Col span={12}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Title level={5} style={{ marginLeft: '10px', paddingLeft:'14px' }}>Question Content</Title>
                                        </div>
                                        </Col>
                                    </Row>
                                    </div>
                                <Checkbox.Group value={selectedQuestions} onChange={(values) => {
                                    setSelectedQuestions(values);
                                    form.setFieldsValue({ questions: values }); // Update form's value directly
                                }}>
                                    <Row gutter={[16, 16]}>
                                        {questions.map((question, index) => (
                                            <Col key={question.id} span={12}>
                                                <Checkbox value={question.id}>
                                                    {question.text} ({question.category})
                                                </Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                            
                        <div style={{ backgroundColor: '#F6F7FB', borderRadius: '10px', paddingLeft: '70px'}}>
                        <Form.Item
                            name="logoURL"
                            rules={[{ required: true, message: 'Please select a logo' }]}
                        >
                            <Radio.Group 
                                onChange={(e) => setSelectedLogo(e.target.value)} 
                                value={selectedLogo}
                                style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                            >
                                {logos.map((logo) => (
                                    <div 
                                        key={logo.LogoID} 
                                        style={{ 
                                            padding: '10px', 
                                            display: 'flex', 
                                            alignItems: 'center',
                                            flex: '1 0 auto'  // Allow items to grow and shrink, but prevent them from exceeding the container's width
                                        }}
                                    >
                                        <Radio 
                                            value={logo.LogoURL} 
                                            style={{ 
                                                display: 'flex', 
                                                alignItems: 'center' 
                                            }}
                                        >
                                            <img 
                                                src={logo.LogoURL} 
                                                alt={logo.LogoName} 
                                                style={{ 
                                                    width: '100px', 
                                                    height: '100px', 
                                                    objectFit: 'contain', 
                                                    marginRight: '10px' 
                                                }} 
                                            />
                                            <div>{logo.LogoName}</div>
                                        </Radio>
                                    </div>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                        </div>
                            {loading && <Spin />}
                            {url && (
                                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                    <Title level={4}>{url}</Title>
                                    <Button type="dashed" onClick={handleCopyUrl} icon={<CopyOutlined />}>
                                        Copy URL
                                    </Button>
                                    <Button type="dashed" onClick={handleClear} icon={<CloseCircleOutlined />}>
                                        Clear
                                    </Button>
                                </div>
                            )}
                        
                        </Form>
                    </div>
                </Col>
                <Col span={24} md={12}>
                    <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px',
                paddingTop: '27px',
                minHeight: '450px'
            }} >
                        <Form layout="vertical" form={evaluatorForm} onFinish={handleSubmitEvaluator}>
                        <Row gutter={24} align="middle" style={{marginBottom: '20px'}}> {/* Add align="middle" to vertically center items */}
                        <Col span={5} style={{ display: 'flex', alignItems: 'center' }}> {/* Center items vertically */}
                            <Title level={5} style={{ margin: 0 }}>Evaluator Details</Title> {/* Remove default margin */}
                        </Col>
                        <Col span={13} style={{  alignItems: 'center' }}> {/* Center items vertically */}
                            <Form.Item style={{ margin: 0 }}>
                                <Input value={hash} readOnly disabled={!hash} style={{ 
                                                backgroundColor: '#F6F7FB',
                                                borderRadius: '10px',
                                                border: '1px solid #D9D9D9',
                                                fontWeight: 'bold',
                                                width: '100%'
                                            }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ display: 'flex', alignItems: 'center' }}> {/* Center items vertically */}
                            <Form.Item style={{ margin: 0, width: '100%' }}>
                                <Button 
                                    type="primary" 
                                    htmlType="submit" 
                                    loading={evaluatorLoading} 
                                    block
                                    style={{
                                        maxWidth: '50%',
                                        float: 'right',
                                        backgroundColor: '#3B7DDE',
                                    }}
                                >
                                    SUBMIT
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} >
                    <Col span={12} >
                        <Title level={5} style={
                        {
                            margin:0,
                            padding:0,
                            paddingBottom: '5px',
                            paddingTop: '20px'
                        }}>Evaluator Email</Title>
                    </Col>
                    <Col span={12}>
                        <Title level={5} style={
                        {
                            margin:0,
                            padding:0,
                            paddingBottom: '5px',
                            paddingTop: '20px'
                        }}>Evaluator Name</Title>
                    </Col>
                </Row>
                            {[0, 1, 2, 3, 4].map((index) => (
                                <Row key={index} gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            rules={[{ required: true, message: 'Please input the email' }]}
                                        >
                                            <Input
                                                value={evaluators[index].email}
                                                onChange={(e) => handleEvaluatorChange(index, 'email', e.target.value)}
                                                disabled={!hash}
                                                placeholder={`Evaluator Email ${index + 1}`}
                                                style={{ 
                                                backgroundColor: '#F6F7FB',
                                                borderRadius: '10px',
                                                border: '1px solid #D9D9D9',
                                                fontWeight: 'bold',
                                                width: '100%'
                                            }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            rules={[{ required: true, message: 'Please input the name' }]}
                                        >
                                            <Input
                                                value={evaluators[index].name}
                                                onChange={(e) => handleEvaluatorChange(index, 'name', e.target.value)}
                                                disabled={!hash}
                                                placeholder={`Evaluator Name ${index + 1}`}
                                                style={{ 
                                                backgroundColor: '#F6F7FB',
                                                borderRadius: '10px',
                                                border: '1px solid #D9D9D9',
                                                fontWeight: 'bold',
                                                width: '100%'
                                            }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                        </Form>
                    </div>
                
                {isEvaluatorSubmitted && (
                        <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingTop: '30px',
                paddingBottom: '79px',
                minHeight: '587px'
            }} >
                        <EmailSender
                            evaluators={evaluators}
                            companyName={selectedName} // Pass selectedName (updated above) to EmailSender
                            numberOfQuestionsSelected={numberOfQuestionsSelected}
                            fullName={fullName}
                            URL={url}
                        />
                        </div>

                )}
                </Col>
            </Row>
        </div>
    );
}

export default AddEvaluatee;