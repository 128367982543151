import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import LoginForm from './components/LoginForm';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoutes from './components/ProtectedRoutes';
import AdminDashboard from './components/AdminDashboard';
import Questions from './components/Questions';
import Report from './components/Report';
import AddEvaluatee from './components/AddEvaluatee';
import Sidebar from './components/Sidebar';
import Reminder from './components/Reminder';
import TestEvaluatee from './components/TestEvaluatee';
import ReportCompletion from './components/ReportCompletion';
import YearReport from './components/YearReport';
import CategoryReport from './components/CategoryReport';
import SelfResponses from './components/SelfResponses'
import CompanyReport from './components/CompanyReport'
import EvaluationResponses from './components/EvaluationResponses';
import './index.css';
import './App.css';
import AppHeader from './components/AppHeader';

const { Content } = Layout;

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginForm />} />


          <Route element={<ProtectedRoutes />}>
            <Route element={<MainLayout />}>
              <Route path="/" element={<AddEvaluatee />} />
              <Route path="detailed-report" element={<AdminDashboard />} />
              <Route path="questions" element={<Questions />} />
              <Route path="report" element={<Report />} />
              <Route path="reminder" element={<Reminder />} />
              <Route path="evaluatoredit" element={<TestEvaluatee />} />
              <Route path="reportcompletion" element={<ReportCompletion />} />
              <Route path="yearreport" element={<YearReport />} />
              <Route path="categoryreport" element={<CategoryReport />} />
              <Route path="selfresponses" element={<SelfResponses />} />
              <Route path="companyreport" element={<CompanyReport />} />
              <Route path="evaluationresponses" element={<EvaluationResponses />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

const MainLayout = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <Sidebar />
    <Layout className="site-layout">
      <AppHeader />
      <Content style={
        {
          marginTop: '-20px',
        }
      }>
        <Outlet />
      </Content>
    </Layout>
  </Layout>
);

export default App;